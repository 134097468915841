import { createBrowserRouter, Outlet } from "react-router-dom";

import App from "./App";
import Agree from "./app/Agree";
import Cancel from "./app/Cancel";
import Clear from "./app/Clear";
import Main from "./app/Main";
import Reservation from "./app/Reservation";
import Search from "./app/Search";
import NiceResult from "./app/NiceResult";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <App />,
      },
      {
        path: "home",
        element: <Main />,
      },
      {
        path: "agree",
        element: <Agree />,
      },
      {
        path: "reservation",
        element: <Reservation />,
      },
      {
        path: "clear",
        element: <Clear />,
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "cancel",
        element: <Cancel />,
      },
      {
        path: "niceresult",
        element: <NiceResult />,
      },
    ],
  },
]);

export default router;
